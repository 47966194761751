import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { GatsbyImage as Img } from 'gatsby-plugin-image';

function ProductPreview({
  path = '',
  image = null,
  name = 'Engaging Adolecents',
  brand = 'Michael Hawton',
  price = '29.90',
  type = 'resource',
}) {
  return (
    <Box w="100%">
      <Link to={`/shop/${path}`}>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="start"
          color="gray.800"
          transition=".1s ease-out all"
          w="100%"
        >
          <Flex
            w="100%"
            boxShadow="lg"
            position="relative"
            transition=".2s ease-out all"
            _hover={{ boxShadow: 'xl', transform: 'translateY(-5px)' }}
          >
            {image ? (
              <Img image={image} alt={name} />
            ) : (
              <Box h="full" flexGrow={1} />
            )}
          </Flex>
          <Flex flexDir="column" mt={6} alignItems="start">
            <Heading as="h4" fontSize="1.2rem">
              {name}
            </Heading>
            <Text
              fontSize=".9rem"
              maxW="150px"
              color="gray.600"
              mt={2}
              mb={3}
            >{`By ${brand}`}</Text>
            <Text
              px={3}
              py={1}
              borderRadius="2xl"
              bgColor="white"
              color="gray.800"
              fontWeight="semibold"
              fontSize="sm"
              border="1px solid"
              borderColor="gray.200"
            >{`$${Number(price).toFixed(2)}`}</Text>
          </Flex>
        </Flex>
      </Link>
    </Box>
  );
}

export default ProductPreview;
