import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import slugify from 'slugify';

export default function NewBookSm() {
  const [slug, setSlug] = React.useState<string>('');
  const data = useStaticQuery(graphql`
    query {
      book: shopifyProduct(title: { eq: "The Anxiety Coach" }) {
        title
        description
        priceRangeV2 {
          minVariantPrice {
            amount
          }
        }
        media {
          ... on ShopifyMediaImage {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400)
                }
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (data?.book?.title) {
      const s = slugify(data.book.title, { lower: true });
      setSlug(`${s}`);
    }
  }, [data]);

  return (
    <Flex w={'100%'} flexDir={'row'} pb={12} align={'start'} gap={6}>
      <Flex
        boxShadow={'lg'}
        borderRadius={'lg'}
        overflow={'hidden'}
        w={'200px'}
      >
        <Img
          image={
            data.book.media[0].image.localFile.childImageSharp.gatsbyImageData
          }
          alt={data.book.title}
        />
      </Flex>
      <Flex flexDir={'column'} gap={2} align={'start'}>
        <Heading as={'h2'} size={'lg'}>
          {data.book.title}
        </Heading>
        <Text color={'gray.600'}>By Michael Hawton</Text>
        <Text
          px={3}
          py={1}
          fontSize={'sm'}
          fontWeight={'semibold'}
          borderRadius={'full'}
          bgColor={'white'}
          border="1px solid"
          borderColor={'gray.200'}
        >
          {`$${Number(data.book.priceRangeV2.minVariantPrice.amount)}`}
        </Text>
        <Link to={slug}>
          <Button
            colorScheme={'secondary'}
            rightIcon={<FiArrowRight />}
            size={'sm'}
          >
            Buy now
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
}
